import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import i18Data from '../../content/intl/labels.json';
import { getTranslations, categoriesStringToSlugsArray, getParamsFromQs, createSlug } from "../components/helpers/helpers";
import { replaceDataIds } from "../components/helpers/prevDataIds";

import "../styles/publications.scss"

const noResults = [];

class PublicationsSearch extends Component {

    constructor(props) {
        super(props);

        const lang = this.props.pageContext.lang;

        let parms = getParamsFromQs(props.location.search ? props.location.search.substring(1).split("&") : [], { q: "", types: "", areas: "", categories: ""}, true);
        let replaceUrl = false;

        if (parms.tema) { parms.categories = replaceDataIds("categories", parms.tema, lang); replaceUrl = true; }
        if (parms.area) { parms.areas = replaceDataIds("areas", parms.area, lang); replaceUrl = true; }
        if (parms.tipo) { parms.types = replaceDataIds("types", parms.tipo, lang); replaceUrl = true; }
        parms.q = decodeURI(parms.q);

        parms.categories = parms.categories ? parms.categories.split(",").map(s => s.trim()) : [];
        parms.areas = parms.areas ? parms.areas.split(",").map(s => s.trim()) : []; 
        parms.types = parms.types ? parms.types.split(",").map(s => s.trim()) : [];

        this.state = {
            q: parms.q,
            types: parms.types || [],
            areas: parms.areas || [],
            categories: parms.categories || [],
            dataFiltered: this.doSearch(parms).sort(sortOrderThenDate)
        }

        this.updateCheckbox = this.updateCheckbox.bind(this);

        if (replaceUrl) {
            let newParams = ["types", "areas", "categories"].map(key => this.state[ key ].length ? `${key}=${this.state[ key ].join(",")}` : "").filter(s => !!s).join("&");
            if (this.state.q) { newParams = "q=" + this.state.q + (newParams ? "&" +  newParams : "");}
            navigate(`${this.props.location.pathname}?${newParams}`, { replace: true });
        }

    }

    onSubmitFn(ev) {

        ev.preventDefault();

        const getCheckedValues = nList => {
            return Array.prototype.slice.call(nList).filter(n => n.checked).map(n => n.value);
        }

        const query = {
            q: this.searchTextInput.value,
            areas: getCheckedValues(ev.target.elements.areas),
            categories: getCheckedValues(ev.target.elements.categories),
            types: getCheckedValues(ev.target.elements.types),
        };

        let params = ["types", "areas", "categories"].map(key => query[ key ].length ? `${key}=${query[ key ].join(",")}` : "").filter(s => !!s).join("&");
        if (query.q) { params = "q=" + query.q + (params ? "&" +  params : "");}

        navigate(`${this.props.location.pathname}?${params}`);

        this.setState({ dataFiltered: this.doSearch(query).sort(sortOrderThenDate) });

        //this.setState(query);
        
        return false;
    }

    updateCheckbox(name, value, isChecked) {
        const vs = this.state[ name ].slice();
        if (isChecked) {
            if (vs.indexOf(value) < 0) {
                vs.push(value);
                this.setState({ [name]: vs });
            }
        } else {
            let pos = vs.indexOf(value);
            if (pos >= 0) {
                vs.splice(pos, 1);
                this.setState({ [name]: vs });
            }
        }
    }

    doSearch(st) {
        if (st.q || st.types.length || st.areas.length || st.categories.length) {
            return this.props.data.pubData.edges.filter(({ node }) => {
                if (node.frontmatter.lang !== this.props.pageContext.lang) { return false; }
                const meetConds = { q: true, t: true, a: true, c: true };
                if (st.q) {
                    let terms = String(st.q).replace(/,/gm," ").split(" "),
                        meetsCondQi = true;
                    for (let i = 0; i < terms.length; i += 1) {
                        let term = new RegExp(addLatinChars(terms[ i ]), "igm");
                        meetsCondQi = node.frontmatter.title.match(term)
                            || node.frontmatter.card.match(term)
                            || node.html.match(term);
                        if (!meetsCondQi) { break; }
                    }
                    meetConds.q = meetsCondQi;
                }
                if (st.types.length) {
                    let typeSlug = createSlug(node.frontmatter.type);
                    if (st.types.indexOf(typeSlug) >= 0) { meetConds.t = true; }
                    else { meetConds.t = false; }
                }
                if (st.areas.length) {
                    let areasSlugs = categoriesStringToSlugsArray(node.frontmatter.areas).map(s => s.slug);
                    if (areasSlugs.length) {
                        if (st.areas.some(str => areasSlugs.indexOf(str) >= 0)) { meetConds.a = true;}
                        else { meetConds.a = false; }
                    } else { meetConds.a = false; }
                }
                if (st.categories.length) {
                    let catsSlugs = categoriesStringToSlugsArray(node.frontmatter.categories).map(s => s.slug);
                    if (catsSlugs.length) {
                        if (st.categories.some(str => catsSlugs.indexOf(str) >= 0)) { meetConds.c = true; }
                        else { meetConds.c = false; }
                    } else { meetConds.c = false; }
                }
                return meetConds.q && meetConds.t && meetConds.a && meetConds.c;
            });

        } else {
            return noResults;
        }

        function addLatinChars(str) {
            let repl = "";
            const vowels = [ "aàáäâ", "eèéëê", "iìíïî", "oòóöô", "uùúüû", "nñ" ];
            const vowelsDictionay = {};

            vowels.forEach(vGroup => {
                for (let i = 0; i < vGroup.length; i += 1) {
                    let vow = vGroup[ i ];
                    vowelsDictionay[ vow ] = vGroup;
                }
            });

            for (let j = 0; j < str.length; j += 1) {
                let letter = str[ j ];
                if (!vowelsDictionay[ letter ]) {
                    repl += letter;
                } else {
                    repl += `[${vowelsDictionay[ letter ]}]`;
                }

            }

            return repl;
        }
    }

    render() {
        const { data: { pageData, pubData }, pageContext: { lang } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "publicationsSearch");
        const i18 = i18Data[ lang ];

        let allTags = [], allAreas = [], allTypes = [];
        pubData.edges.forEach(({ node }) => {
            allTags = allTags.concat((node.frontmatter.categories || "").split(",").map(s => s.trim()));
            allAreas = allAreas.concat((node.frontmatter.areas || "").split(",").map(s => s.trim()));
            allTypes.push(String(node.frontmatter.type || "").trim());
        });

        allTags = allTags.filter((s, i) => s && allTags.indexOf(s) === i).sort();
        allAreas = allAreas.filter((s, i) => s && allAreas.indexOf(s) === i).sort();
        allTypes = allTypes.filter((s, i) => s && allTypes.indexOf(s) === i).sort();

        const dataFiltered = this.state.dataFiltered;


        return (
            <Layout mainClassName="publications" data={{}} noActiveMenu={false} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={page.frontmatter.summary} lang={lang ? lang : "en" }/>


                <div className={`publications-container initial-padding in-search`}>

                    <div className="publications-top" >
                        <h1>{page.frontmatter.title}</h1>                                            
                    </div>

                    <h2 className="hx-area w50">{`${i18.labels.results}: ${dataFiltered.length}`}</h2>
                    <div className="publications-content in-search">
                        { dataFiltered.map((art, idx) =>
                            <article className={`article-n-${idx}`} key={art.node.frontmatter.slug}>
                                <Link to={`../${art.node.frontmatter.slug}`}>
                                    <span dangerouslySetInnerHTML={{ __html: art.node.frontmatter.card }} /><span></span>
                                    <span className="big-link"></span>
                                </Link>
                            </article>
                        )}                        
                    </div>

                    <div className="top-relations first-pad-top in-search">
                        <form className="form-search" onSubmit={this.onSubmitFn.bind(this)}>
                            <div className="pub-search">
                                <input 
                                    name="searchText" type="search" 
                                    placeholder={i18.labels.searchBox}
                                    value={this.state.q}
                                    onChange={e => this.setState({q: e.target.value })}
                                    ref={(t) => { this.searchTextInput = t }} />
                                <button className="search-go" 
                                    aria-label={i18.labels.searchGo}>
                                <span className="big-link"></span></button>
                            </div>
                            
                            <div className="relations-section">
                                <h3>{i18.labels.categories}</h3>
                                <ul>
                                {categoriesStringToSlugsArray(allTags).map(cat => {
                                    return(
                                        <li key={cat.slug}>
                                            <input type="checkbox" name="categories" id={`cat-${cat.slug}`} value={cat.slug} 
                                                checked={this.state.categories.indexOf(cat.slug)>=0}
                                                onChange={ev => {this.updateCheckbox("categories", cat.slug, ev.target.checked)}}
                                                />
                                            <label htmlFor={`cat-${cat.slug}`}>{cat.text}</label>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                        
                            <div className="relations-section">
                                <h3>{i18.labels.areas}</h3>
                                <ul>
                                {categoriesStringToSlugsArray(allAreas).map(area => {
                                    return(
                                        <li key={area.slug}>
                                            <input type="checkbox" name="areas" id={`area-${area.slug}`} value={area.slug} 
                                                checked={this.state.areas.indexOf(area.slug)>=0}
                                                onChange={ev => {this.updateCheckbox("areas", area.slug, ev.target.checked)}}
                                                />
                                            <label htmlFor={`area-${area.slug}`}>{area.text}</label>                                    
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>

                            <div className="relations-section">
                                <h3>{i18.labels.types}</h3>
                                <ul>
                                {categoriesStringToSlugsArray(allTypes).map(type => {
                                    return(
                                        <li key={type.slug}>
                                            <input type="checkbox" name="types" id={`type-${type.slug}`} 
                                                value={type.slug} 
                                                checked={this.state.types.indexOf(type.slug)>=0}
                                                onChange={ev => {this.updateCheckbox("types", type.slug, ev.target.checked)}}
                                                />
                                            <label htmlFor={`type-${type.slug}`}>{type.text}</label>                                    
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                        
                        </form>
                    </div>

                </div>

            </Layout>
        )
    }
}

export default PublicationsSearch


export const query = graphql`
    query PublicationsSearchQuery($lang: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "publicationsSearch" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                    }
                    id
                    html

                }
            }
        }
        pubData: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: $lang }, template:{ eq: "publication" }}},
        ){
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        title
                        translateKey
                        order
                        card
                        pubDate
                        categories
                        areas
                        order
                        type
                    }
                    id
                    html

                }
            }
        }

    }
`

function sortOrderThenDate(a, b) {
    let aD = new Date(a.node.frontmatter.pubDate),
        bD = new Date(b.node.frontmatter.pubDate),
        aS = a.node.frontmatter.order,
        bS = b.node.frontmatter.order,
        orderDate = aD === bD ? 0 : (aD > bD ? -1 : 1),
        orderOrder = aS === bS ? 0 : (aS > bS ? -10 : 10);

    return orderOrder + orderDate;
}