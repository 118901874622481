const { createSlug } = require("./helpers")

const prevData = {
    "areas": {
        "1": {
            "id": 1,
            "es": "Derecho y Economía",
            "en": "Law & Economics",
            "ca": "Derecho y Economía"
        },
        "2": {
            "id": 2,
            "es": "Política de la Competencia",
            "en": "Competition Policy",
            "ca": "Política de la Competencia"
        },
        "3": {
            "id": 3,
            "es": "Regulación",
            "en": "Regulation",
            "ca": "Regulación"
        },
        "4": {
            "id": 4,
            "es": "Instituciones",
            "en": "Institutions",
            "ca": "Instituciones"
        },
        "5": {
            "id": 5,
            "es": "Organización",
            "en": "Organization",
            "ca": "Organización"
        },
        "6": {
            "id": 6,
            "es": "Organización Industrial",
            "en": "Industrial Organization",
            "ca": "Organización Industrial"
        },
        "7": {
            "id": 7,
            "es": "Teoría de la Empresa",
            "en": "Theory of the Firm",
            "ca": "Teoría de la Empresa"
        },
        "8": {
            "id": 8,
            "es": "Gestión Pública",
            "en": "Public Management",
            "ca": "Gestión Pública"
        },
        "9": {
            "id": 9,
            "es": "Comportamiento",
            "en": "Behavior",
            "ca": "Comportamiento"
        },
        "10": {
            "id": 10,
            "es": "Medida",
            "en": "Measurement",
            "ca": "Medida"
        },
        "11": {
            "id": 11,
            "es": "Misceláneo",
            "en": "Miscellaneous",
            "ca": "Misceláneo"
        }
    },
    "categories": {
        "1": {
            "id": 1,
            "es": "Contratos",
            "en": "Contracts",
            "ca": "Contractes"
        },
        "2": {
            "id": 2,
            "es": "Integración vertical",
            "en": "Vertical integration",
            "ca": "Integració vertical"
        },
        "3": {
            "id": 3,
            "es": "Subcontratación",
            "en": "Subcontracting",
            "ca": "Subcontractació"
        },
        "4": {
            "id": 4,
            "es": "Gobierno corporativo",
            "en": "Corporate governance",
            "ca": "Govern corporatiu"
        },
        "5": {
            "id": 5,
            "es": "Formalización de empresas",
            "en": "Business formalization",
            "ca": "Formalització d’empreses"
        },
        "6": {
            "id": 6,
            "es": "Propiedad",
            "en": "Property titling",
            "ca": "Propietat"
        },
        "7": {
            "id": 7,
            "es": "Judicatura",
            "en": "Judiciary",
            "ca": "Judicatura"
        },
        "8": {
            "id": 8,
            "es": "Profesiones",
            "en": "Professions",
            "ca": "Professions jurídiques"
        },
        "9": {
            "id": 9,
            "es": "Profesiones",
            "en": "Professions",
            "ca": "Professions no jurídiques"
        },
        "10": {
            "id": 10,
            "es": "Seguro de títulos",
            "en": "Title insurance",
            "ca": "Segur de títols"
        },
        "11": {
            "id": 11,
            "es": "Auditoría",
            "en": "Auditing",
            "ca": "Auditoria"
        },
        "12": {
            "id": 12,
            "es": "Sanidad",
            "en": "Healthcare",
            "ca": "Sanitat"
        },
        "13": {
            "id": 13,
            "es": "Educación",
            "en": "Education",
            "ca": "Educació"
        },
        "14": {
            "id": 14,
            "es": "Distribución",
            "en": "Distribution",
            "ca": "Distribució"
        },
        "15": {
            "id": 15,
            "es": "Servicios (otros)",
            "en": "Other services",
            "ca": "Serveis (altres)"
        },
        "16": {
            "id": 16,
            "es": "Religión",
            "en": "Religion",
            "ca": "Religió"
        },
        "17": {
            "id": 17,
            "es": "Unión Europea",
            "en": "European Union",
            "ca": "Unió Europea"
        },
        "18": {
            "id": 18,
            "es": "España",
            "en": "Spain",
            "ca": "Espanya"
        }
    },
    "types": {
        "1": {
            "id": 1,
            "es": "Libro",
            "en": "Book",
            "ca": "Llibre"
        },
        "2": {
            "id": 2,
            "es": "Artículo",
            "en": "Article",
            "ca": "Article"
        },
        "3": {
            "id": 3,
            "es": "Capítulo de libro",
            "en": "Book chapter",
            "ca": "Capítol de llibre"
        },
        "4": {
            "id": 4,
            "es": "Documento de trabajo",
            "en": "Working Paper",
            "ca": "Document de treball"
        },
        "5": {
            "id": 5,
            "es": "Opinión",
            "en": "Op-ed ",
            "ca": "Opinió"
        },
        "6": {
            "id": 6,
            "es": "Conferencia",
            "en": "Presentation",
            "ca": "Conferencia"
        },
        "7": {
            "id": 7,
            "es": "Recensión",
            "en": "Review",
            "ca": "Recensió"
        }
    }
}

const replaceDataIds = function(name, values, lang) {
    let vals = values.replace(/,/igm, "+").split("+");
    let nVals = vals.map(v => 
        createSlug(prevData[ name ][ v ][ lang ])
    );
    return nVals.join(",");
}

exports.replaceDataIds = replaceDataIds;